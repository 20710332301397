import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Button
{
    constructor(name,texture,position)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.textures = {}
        this.textures.alpha = texture
        this.textures.card = texture
        this.material = new THREE.MeshBasicMaterial({
            map: this.textures.card,
            alphaMap:this.textures.alpha,
            transparent:true,
        })
        this.geometry = new THREE.PlaneGeometry()
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.mesh.position.set(position[0],position[1],position[2])
        this.mesh.name = name
        this.scene.add(this.mesh)
    }
}