import * as THREE from 'three'
import Experience from '../Experience.js'
import gsap from 'gsap'


export default class Card
{
    constructor(name, cat, sel, front, back, position)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.world = this.experience.world
        this.category = cat
        this.front = false
        this.clickable = true
        this.final = false
        this.front_pos = sel
        this.start_pos = position
        this.rotation_displacement = Math.random()
        this.rotation_amplitude = Math.random() * 0.3 + 0.7
        this.rotation_speed = 0.005
        this.setTextures(front,back)
        this.setMaterial()
        this.setGeometry()
        this.setMesh(name,position)
    }
    
    setTextures(front, back)
    {
        this.textures = {}
        this.textures.alpha = this.resources.items.alpha
        this.textures.normal = this.resources.items.papernormal
        this.textures.front = front
        this.textures.back = back
    }
    
    setMaterial()
    {
        this.material =[
            new THREE.MeshBasicMaterial({transparent:true, opacity:0}),
            new THREE.MeshBasicMaterial({transparent:true, opacity:0}),
            new THREE.MeshBasicMaterial({transparent:true, opacity:0}),
            new THREE.MeshBasicMaterial({transparent:true, opacity:0}),
            new THREE.MeshStandardMaterial({
                map: this.textures.back,
                side: THREE.FrontSide,
                alphaMap: this.textures.alpha,
                normalMap: this.textures.normal,
                roughness: 0.7,
                transparent:true,
            }),
            new THREE.MeshStandardMaterial({
                map: this.textures.front,
                side: THREE.FrontSide,
                alphaMap: this.textures.alpha,
                normalMap: this.textures.normal,
                roughness: 0.7,
                transparent:true,
            })]
    }
    
    setGeometry()
    {
        this.geometry = new THREE.BoxGeometry(3.6, 5,0.003)
    }
    
    setMesh(name,position)
    {
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.mesh.position.set(position[0],position[1],position[2])
        this.mesh.name = name
        this.scene.add(this.mesh)
    }
    
    spin()
    {
        var zspin='+=6.28318530718'
        gsap.to(this.mesh.rotation, {duration: 1.0, ease: 'elastic.inOut', y: zspin, })
        gsap.to(this.mesh.scale, {duration: 0.5, repeat:1, yoyo:true, ease: 'elastic.inOut', x:1.2,y:1.2})
    }
    
    click_to_front()
    {
        // moves card to front, if there is space in front layer and card is not already in front
        if ((!this.front) && (this.world.front_objects.length<3))
        {
            gsap.to(this.mesh.position, { duration: 0.7, ease: 'back.out', delay: this.front_pos*0.2, x: this.front_pos*5-5, y: Math.abs(this.front_pos-1)*0.3, z: 2, })
            gsap.to(this.mesh.rotation, { duration: 0.7, ease: 'back.out', delay: this.front_pos*0.2, y: '-=3.14159', })
            this.front = true
            this.world.front_objects.push(this)
        }
    }
    
    selected()
    {
        // if card was selected, spin, put to final place, increase rotation speed (happy), clear front
        this.spin()
        this.final_place()
        this.rotation_speed *=2
        this.world.clean_up_front()
    }
    
    final_place()
    {
        gsap.to(this.mesh.position, { duration: 2, delay: 1, ease: 'back.out',
            x: this.category * 4 - 10,
            y: -4 + Math.abs(this.category-2.5)*0.3,
            z: -6 + this.category *0.5, })
        gsap.to(this.mesh.rotation, { duration: 2, ease: 'elastic.out', y: 3.14159})
        this.final = true
    }
    
    sad_leave()
    {
        if (!this.final)
        {
            gsap.to(this.mesh.position, {duration: 0.1, repeat:6, yoyo:true, ease: 'rough.inOut', x:'+=1'})
            gsap.to(this.mesh.position, { duration: 0.5, delay: this.rotation_amplitude * 0.6, ease: 'back.out', y: -20})
            this.final = true
        }
    }
    
    reset_pos_rot()
    {
        this.mesh.position.set(this.start_pos[0],this.start_pos[1],this.start_pos[2])
        this.mesh.rotation.set(0,0,0)
        this.front = false
        this.clickable = true
        this.final = false
    }

    update()
    {
        if (this.front)
        {
            this.mesh.rotation.y += Math.cos(this.time.elapsed * this.rotation_speed + this.rotation_displacement * Math.PI) * this.time.delta *0.0001 * this.rotation_amplitude
            this.mesh.rotation.z += Math.cos(this.time.elapsed * this.rotation_speed * 0.5 + this.rotation_displacement * Math.PI) * this.time.delta * 0.00005  * this.rotation_amplitude
        }
        if (this.final)
        {
            this.mesh.position.y += Math.cos(this.time.elapsed *0.001 + this.rotation_displacement * Math.PI) * this.time.delta *0.0001
            this.mesh.position.x += Math.cos(this.time.elapsed *0.001 + this.rotation_displacement * Math.PI) * this.time.delta *0.0001
        }
    }
}