export default [
    {
        name: 'button_reset',
        type: 'texture',
        path: 'textures/cards/Element01.jpg'
    },
    {
        name: 'button_link',
        type: 'texture',
        path: 'textures/cards/Element02.jpg'
    },
    {
        name: 'alpha',
        type: 'texture',
        path: 'textures/cards/Alpha.jpg'
    },
    //back sides
    {
        name: 'card_back_1',
        type: 'texture',
        path: 'textures/cards/Proc_000.jpg'
    },
    {
        name: 'card_back_2',
        type: 'texture',
        path: 'textures/cards/Proc_001.jpg'
    },
    {
        name: 'card_back_3',
        type: 'texture',
        path: 'textures/cards/Proc_002.jpg'
    },
    {
        name: 'card_back_4',
        type: 'texture',
        path: 'textures/cards/Proc_003.jpg'
    },
    {
        name: 'card_back_5',
        type: 'texture',
        path: 'textures/cards/Proc_004.jpg'
    },
    {
        name: 'card_back_6',
        type: 'texture',
        path: 'textures/cards/Proc_005.jpg'
    },
    //category 1
    {
        name: 'cat_01_front_01',
        type: 'texture',
        path: 'textures/cards/Proc_006.jpg'
    },
    {
        name: 'cat_01_front_02',
        type: 'texture',
        path: 'textures/cards/Proc_007.jpg'
    },
    {
        name: 'cat_01_front_03',
        type: 'texture',
        path: 'textures/cards/Proc_008.jpg'
    },
    {
        name: 'cat_01_front_04',
        type: 'texture',
        path: 'textures/cards/Proc_009.jpg'
    },
    {
        name: 'cat_01_front_05',
        type: 'texture',
        path: 'textures/cards/Proc_010.jpg'
    },
    {
        name: 'cat_01_front_06',
        type: 'texture',
        path: 'textures/cards/Proc_011.jpg'
    },
    {
        name: 'cat_01_front_07',
        type: 'texture',
        path: 'textures/cards/Proc_012.jpg'
    },
    {
        name: 'cat_01_front_08',
        type: 'texture',
        path: 'textures/cards/Proc_013.jpg'
    },
    {
        name: 'cat_01_front_09',
        type: 'texture',
        path: 'textures/cards/Proc_014.jpg'
    },
    {
        name: 'cat_01_front_10',
        type: 'texture',
        path: 'textures/cards/Proc_015.jpg'
    },    {
        name: 'cat_01_front_11',
        type: 'texture',
        path: 'textures/cards/Proc_016.jpg'
    },
    {
        name: 'cat_01_front_12',
        type: 'texture',
        path: 'textures/cards/Proc_017.jpg'
    },
    {
        name: 'cat_01_front_13',
        type: 'texture',
        path: 'textures/cards/Proc_018.jpg'
    },
    {
        name: 'cat_01_front_14',
        type: 'texture',
        path: 'textures/cards/Proc_019.jpg'
    },
    {
        name: 'cat_01_front_15',
        type: 'texture',
        path: 'textures/cards/Proc_020.jpg'
    },
    {
        name: 'cat_01_front_16',
        type: 'texture',
        path: 'textures/cards/Proc_021.jpg'
    },
    {
        name: 'cat_01_front_17',
        type: 'texture',
        path: 'textures/cards/Proc_022.jpg'
    },
    {
        name: 'cat_01_front_18',
        type: 'texture',
        path: 'textures/cards/Proc_023.jpg'
    },
    {
        name: 'cat_01_front_19',
        type: 'texture',
        path: 'textures/cards/Proc_024.jpg'
    },
    {
        name: 'cat_01_front_20',
        type: 'texture',
        path: 'textures/cards/Proc_025.jpg'
    },
    //category 2
    {
        name: 'cat_02_front_01',
        type: 'texture',
        path: 'textures/cards/Proc_026.jpg'
    },
    {
        name: 'cat_02_front_02',
        type: 'texture',
        path: 'textures/cards/Proc_027.jpg'
    },
    {
        name: 'cat_02_front_03',
        type: 'texture',
        path: 'textures/cards/Proc_028.jpg'
    },
    {
        name: 'cat_02_front_04',
        type: 'texture',
        path: 'textures/cards/Proc_029.jpg'
    },
    {
        name: 'cat_02_front_05',
        type: 'texture',
        path: 'textures/cards/Proc_030.jpg'
    },
    {
        name: 'cat_02_front_06',
        type: 'texture',
        path: 'textures/cards/Proc_031.jpg'
    },
    {
        name: 'cat_02_front_07',
        type: 'texture',
        path: 'textures/cards/Proc_032.jpg'
    },
    {
        name: 'cat_02_front_08',
        type: 'texture',
        path: 'textures/cards/Proc_033.jpg'
    },
    {
        name: 'cat_02_front_09',
        type: 'texture',
        path: 'textures/cards/Proc_034.jpg'
    },
    {
        name: 'cat_02_front_10',
        type: 'texture',
        path: 'textures/cards/Proc_035.jpg'
    },    
    {
        name: 'cat_02_front_11',
        type: 'texture',
        path: 'textures/cards/Proc_036.jpg'
    },
    {
        name: 'cat_02_front_12',
        type: 'texture',
        path: 'textures/cards/Proc_037.jpg'
    },
    {
        name: 'cat_02_front_13',
        type: 'texture',
        path: 'textures/cards/Proc_038.jpg'
    },
    {
        name: 'cat_02_front_14',
        type: 'texture',
        path: 'textures/cards/Proc_039.jpg'
    },
    {
        name: 'cat_02_front_15',
        type: 'texture',
        path: 'textures/cards/Proc_040.jpg'
    },
    {
        name: 'cat_02_front_16',
        type: 'texture',
        path: 'textures/cards/Proc_041.jpg'
    },
    {
        name: 'cat_02_front_17',
        type: 'texture',
        path: 'textures/cards/Proc_042.jpg'
    },
    {
        name: 'cat_02_front_18',
        type: 'texture',
        path: 'textures/cards/Proc_043.jpg'
    },
    {
        name: 'cat_02_front_19',
        type: 'texture',
        path: 'textures/cards/Proc_044.jpg'
    },
    {
        name: 'cat_02_front_20',
        type: 'texture',
        path: 'textures/cards/Proc_045.jpg'
    },
    //category 3
    {
        name: 'cat_03_front_01',
        type: 'texture',
        path: 'textures/cards/Proc_046.jpg'
    },
    {
        name: 'cat_03_front_02',
        type: 'texture',
        path: 'textures/cards/Proc_047.jpg'
    },
    {
        name: 'cat_03_front_03',
        type: 'texture',
        path: 'textures/cards/Proc_048.jpg'
    },
    {
        name: 'cat_03_front_04',
        type: 'texture',
        path: 'textures/cards/Proc_049.jpg'
    },
    {
        name: 'cat_03_front_05',
        type: 'texture',
        path: 'textures/cards/Proc_050.jpg'
    },
    {
        name: 'cat_03_front_06',
        type: 'texture',
        path: 'textures/cards/Proc_051.jpg'
    },
    {
        name: 'cat_03_front_07',
        type: 'texture',
        path: 'textures/cards/Proc_052.jpg'
    },
    {
        name: 'cat_03_front_08',
        type: 'texture',
        path: 'textures/cards/Proc_053.jpg'
    },
    {
        name: 'cat_03_front_09',
        type: 'texture',
        path: 'textures/cards/Proc_054.jpg'
    },
    {
        name: 'cat_03_front_10',
        type: 'texture',
        path: 'textures/cards/Proc_055.jpg'
    },    {
        name: 'cat_03_front_11',
        type: 'texture',
        path: 'textures/cards/Proc_056.jpg'
    },
    {
        name: 'cat_03_front_12',
        type: 'texture',
        path: 'textures/cards/Proc_057.jpg'
    },
    {
        name: 'cat_03_front_13',
        type: 'texture',
        path: 'textures/cards/Proc_058.jpg'
    },
    {
        name: 'cat_03_front_14',
        type: 'texture',
        path: 'textures/cards/Proc_059.jpg'
    },
    {
        name: 'cat_03_front_15',
        type: 'texture',
        path: 'textures/cards/Proc_060.jpg'
    },
    {
        name: 'cat_03_front_16',
        type: 'texture',
        path: 'textures/cards/Proc_061.jpg'
    },
    {
        name: 'cat_03_front_17',
        type: 'texture',
        path: 'textures/cards/Proc_062.jpg'
    },
    {
        name: 'cat_03_front_18',
        type: 'texture',
        path: 'textures/cards/Proc_063.jpg'
    },
    {
        name: 'cat_03_front_19',
        type: 'texture',
        path: 'textures/cards/Proc_064.jpg'
    },
    {
        name: 'cat_03_front_20',
        type: 'texture',
        path: 'textures/cards/Proc_065.jpg'
    },
    //category 4
    {
        name: 'cat_04_front_01',
        type: 'texture',
        path: 'textures/cards/Proc_066.jpg'
    },
    {
        name: 'cat_04_front_02',
        type: 'texture',
        path: 'textures/cards/Proc_067.jpg'
    },
    {
        name: 'cat_04_front_03',
        type: 'texture',
        path: 'textures/cards/Proc_068.jpg'
    },
    {
        name: 'cat_04_front_04',
        type: 'texture',
        path: 'textures/cards/Proc_069.jpg'
    },
    {
        name: 'cat_04_front_05',
        type: 'texture',
        path: 'textures/cards/Proc_070.jpg'
    },
    {
        name: 'cat_04_front_06',
        type: 'texture',
        path: 'textures/cards/Proc_071.jpg'
    },
    {
        name: 'cat_04_front_07',
        type: 'texture',
        path: 'textures/cards/Proc_072.jpg'
    },
    {
        name: 'cat_04_front_08',
        type: 'texture',
        path: 'textures/cards/Proc_073.jpg'
    },
    {
        name: 'cat_04_front_09',
        type: 'texture',
        path: 'textures/cards/Proc_074.jpg'
    },
    {
        name: 'cat_04_front_10',
        type: 'texture',
        path: 'textures/cards/Proc_075.jpg'
    },    {
        name: 'cat_04_front_11',
        type: 'texture',
        path: 'textures/cards/Proc_076.jpg'
    },
    {
        name: 'cat_04_front_12',
        type: 'texture',
        path: 'textures/cards/Proc_077.jpg'
    },
    {
        name: 'cat_04_front_13',
        type: 'texture',
        path: 'textures/cards/Proc_078.jpg'
    },
    {
        name: 'cat_04_front_14',
        type: 'texture',
        path: 'textures/cards/Proc_079.jpg'
    },
    {
        name: 'cat_04_front_15',
        type: 'texture',
        path: 'textures/cards/Proc_080.jpg'
    },
    {
        name: 'cat_04_front_16',
        type: 'texture',
        path: 'textures/cards/Proc_081.jpg'
    },
    {
        name: 'cat_04_front_17',
        type: 'texture',
        path: 'textures/cards/Proc_082.jpg'
    },
    {
        name: 'cat_04_front_18',
        type: 'texture',
        path: 'textures/cards/Proc_083.jpg'
    },
    {
        name: 'cat_04_front_19',
        type: 'texture',
        path: 'textures/cards/Proc_084.jpg'
    },
    {
        name: 'cat_04_front_20',
        type: 'texture',
        path: 'textures/cards/Proc_085.jpg'
    },
    //category 5
    {
        name: 'cat_05_front_01',
        type: 'texture',
        path: 'textures/cards/Proc_086.jpg'
    },
    {
        name: 'cat_05_front_02',
        type: 'texture',
        path: 'textures/cards/Proc_087.jpg'
    },
    {
        name: 'cat_05_front_03',
        type: 'texture',
        path: 'textures/cards/Proc_088.jpg'
    },
    {
        name: 'cat_05_front_04',
        type: 'texture',
        path: 'textures/cards/Proc_089.jpg'
    },
    {
        name: 'cat_05_front_05',
        type: 'texture',
        path: 'textures/cards/Proc_090.jpg'
    },
    {
        name: 'cat_05_front_06',
        type: 'texture',
        path: 'textures/cards/Proc_091.jpg'
    },
    {
        name: 'cat_05_front_07',
        type: 'texture',
        path: 'textures/cards/Proc_092.jpg'
    },
    {
        name: 'cat_05_front_08',
        type: 'texture',
        path: 'textures/cards/Proc_093.jpg'
    },
    {
        name: 'cat_05_front_09',
        type: 'texture',
        path: 'textures/cards/Proc_094.jpg'
    },
    {
        name: 'cat_05_front_10',
        type: 'texture',
        path: 'textures/cards/Proc_095.jpg'
    },    
    {
        name: 'cat_05_front_11',
        type: 'texture',
        path: 'textures/cards/Proc_096.jpg'
    },
    {
        name: 'cat_05_front_12',
        type: 'texture',
        path: 'textures/cards/Proc_097.jpg'
    },
    {
        name: 'cat_05_front_13',
        type: 'texture',
        path: 'textures/cards/Proc_098.jpg'
    },
    {
        name: 'cat_05_front_14',
        type: 'texture',
        path: 'textures/cards/Proc_099.jpg'
    },
    {
        name: 'cat_05_front_15',
        type: 'texture',
        path: 'textures/cards/Proc_100.jpg'
    },
    {
        name: 'cat_05_front_16',
        type: 'texture',
        path: 'textures/cards/Proc_101.jpg'
    },
    {
        name: 'cat_05_front_17',
        type: 'texture',
        path: 'textures/cards/Proc_102.jpg'
    },
    {
        name: 'cat_05_front_18',
        type: 'texture',
        path: 'textures/cards/Proc_103.jpg'
    },
    {
        name: 'cat_05_front_19',
        type: 'texture',
        path: 'textures/cards/Proc_104.jpg'
    },
    {
        name: 'cat_05_front_20',
        type: 'texture',
        path: 'textures/cards/Proc_105.jpg'
    },
    //category 6
    {
        name: 'cat_06_front_01',
        type: 'texture',
        path: 'textures/cards/Proc_106.jpg'
    },
    {
        name: 'cat_06_front_02',
        type: 'texture',
        path: 'textures/cards/Proc_107.jpg'
    },
    {
        name: 'cat_06_front_03',
        type: 'texture',
        path: 'textures/cards/Proc_108.jpg'
    },
    {
        name: 'cat_06_front_04',
        type: 'texture',
        path: 'textures/cards/Proc_109.jpg'
    },
    {
        name: 'cat_06_front_05',
        type: 'texture',
        path: 'textures/cards/Proc_110.jpg'
    },
    {
        name: 'cat_06_front_06',
        type: 'texture',
        path: 'textures/cards/Proc_111.jpg'
    },
    {
        name: 'cat_06_front_07',
        type: 'texture',
        path: 'textures/cards/Proc_112.jpg'
    },
    {
        name: 'cat_06_front_08',
        type: 'texture',
        path: 'textures/cards/Proc_113.jpg'
    },
    {
        name: 'cat_06_front_09',
        type: 'texture',
        path: 'textures/cards/Proc_114.jpg'
    },
    {
        name: 'cat_06_front_10',
        type: 'texture',
        path: 'textures/cards/Proc_115.jpg'
    },    
    {
        name: 'cat_06_front_11',
        type: 'texture',
        path: 'textures/cards/Proc_116.jpg'
    },
    {
        name: 'cat_06_front_12',
        type: 'texture',
        path: 'textures/cards/Proc_117.jpg'
    },
    {
        name: 'cat_06_front_13',
        type: 'texture',
        path: 'textures/cards/Proc_118.jpg'
    },
    {
        name: 'cat_06_front_14',
        type: 'texture',
        path: 'textures/cards/Proc_119.jpg'
    },
    {
        name: 'cat_06_front_15',
        type: 'texture',
        path: 'textures/cards/Proc_120.jpg'
    },
    {
        name: 'cat_06_front_16',
        type: 'texture',
        path: 'textures/cards/Proc_121.jpg'
    },
    {
        name: 'cat_06_front_17',
        type: 'texture',
        path: 'textures/cards/Proc_122.jpg'
    },
    {
        name: 'cat_06_front_18',
        type: 'texture',
        path: 'textures/cards/Proc_123.jpg'
    },
    {
        name: 'cat_06_front_19',
        type: 'texture',
        path: 'textures/cards/Proc_124.jpg'
    },
    {
        name: 'cat_06_front_20',
        type: 'texture',
        path: 'textures/cards/Proc_125.jpg'
    },
    
    {
        name: 'papernormal',
        type: 'texture',
        path: 'textures/cards/paper_normal.jpg'
    },

]