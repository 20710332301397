import EventEmitter from './EventEmitter.js'

export default class Mouse extends EventEmitter
{
    constructor(experience)
    {
        super()
        this.canvas = experience.canvas
        this.sizes = experience.sizes
        this.cursor = {}
        this.cursor.x=0
        this.cursor.y=0
        
        window.addEventListener('mousemove', (event) =>
        {
            let canvasBounds = this.canvas.getBoundingClientRect();
            this.cursor.x =
                ((event.clientX - canvasBounds.left) /
                (canvasBounds.right - canvasBounds.left)) * 2 - 1;
            this.cursor.y = -((event.clientY - canvasBounds.top) /
              (canvasBounds.bottom - canvasBounds.top)) * 2 + 1;
            //console.log(event.clientX,event.clientY)
        })
        window.addEventListener('click', (event) =>
        {
            if (event.button === 0)
            {
                this.trigger('click')
            }
        })
    }
}