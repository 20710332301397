import * as THREE from 'three'
import Experience from '../Experience.js'
import Card from './Card.js'
import Button from './Button.js'
import Environment from './Environment.js'

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.front_objects=[]
        
        // Wait for resources
        this.resources.on('ready', () =>
        {
            this.init_cards()
            this.button_link = new Button('button_link',this.resources.items.button_link,[-10.0,0.-8,-10.0])
            this.button_reset = new Button('button_reset',this.resources.items.button_reset,[-6.0,-8.0,-10.0])

            this.environment = new Environment()
        })
    }

    init_cards()
    {
        let nums = ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20']
        this.cards = [];
        nums.sort(() => 0.5 - Math.random())
        var cat_samples=['cat_01_front_'+nums[0],'cat_01_front_'+nums[1],'cat_01_front_'+nums[2],]
        this.cards.push(new Card('card11', 0, 0, this.resources.items[cat_samples[0]],this.resources.items.card_back_1,[-10.0, 4.5, -9.0]));
        this.cards.push(new Card('card12', 0, 1, this.resources.items[cat_samples[1]],this.resources.items.card_back_1,[-10.0, 4.5, -9.01]));
        this.cards.push(new Card('card13', 0, 2, this.resources.items[cat_samples[2]],this.resources.items.card_back_1,[-10.0, 4.5, -9.02]));
        nums.sort(() => 0.5 - Math.random())
        var cat_samples=['cat_02_front_'+nums[0],'cat_02_front_'+nums[1],'cat_02_front_'+nums[2],]
        this.cards.push(new Card('card21', 1, 0, this.resources.items[cat_samples[0]],this.resources.items.card_back_2,[ -6.0, 5.0,-10.0]));
        this.cards.push(new Card('card22', 1, 1, this.resources.items[cat_samples[1]],this.resources.items.card_back_2,[ -6.0, 5.0,-10.01]));
        this.cards.push(new Card('card23', 1, 2, this.resources.items[cat_samples[2]],this.resources.items.card_back_2,[ -6.0, 5.0,-10.02]));
        nums.sort(() => 0.5 - Math.random())
        var cat_samples=['cat_03_front_'+nums[0],'cat_03_front_'+nums[1],'cat_03_front_'+nums[2],]
        this.cards.push(new Card('card31', 2, 0, this.resources.items[cat_samples[0]],this.resources.items.card_back_3,[ -2.0, 5.3,-10.5]));
        this.cards.push(new Card('card32', 2, 1, this.resources.items[cat_samples[1]],this.resources.items.card_back_3,[ -2.0, 5.3,-10.51]));
        this.cards.push(new Card('card33', 2, 2, this.resources.items[cat_samples[2]],this.resources.items.card_back_3,[ -2.0, 5.3,-10.52]));
        nums.sort(() => 0.5 - Math.random())
        var cat_samples=['cat_04_front_'+nums[0],'cat_04_front_'+nums[1],'cat_04_front_'+nums[2],]
        this.cards.push(new Card('card41', 3, 0, this.resources.items[cat_samples[0]],this.resources.items.card_back_4,[  2.0, 5.3,-10.5]));
        this.cards.push(new Card('card42', 3, 1, this.resources.items[cat_samples[1]],this.resources.items.card_back_4,[  2.0, 5.3,-10.51]));
        this.cards.push(new Card('card43', 3, 2, this.resources.items[cat_samples[2]],this.resources.items.card_back_4,[  2.0, 5.3,-10.52]));
        nums.sort(() => 0.5 - Math.random())
        var cat_samples=['cat_05_front_'+nums[0],'cat_05_front_'+nums[1],'cat_05_front_'+nums[2],]
        this.cards.push(new Card('card51', 4, 0, this.resources.items[cat_samples[0]],this.resources.items.card_back_5,[  6.0, 5.0,-10.0]));
        this.cards.push(new Card('card52', 4, 1, this.resources.items[cat_samples[1]],this.resources.items.card_back_5,[  6.0, 5.0,-10.01]));
        this.cards.push(new Card('card53', 4, 2, this.resources.items[cat_samples[2]],this.resources.items.card_back_5,[  6.0, 5.0,-10.02]));
        nums.sort(() => 0.5 - Math.random())
        var cat_samples=['cat_06_front_'+nums[0],'cat_06_front_'+nums[1],'cat_06_front_'+nums[2],]
        this.cards.push(new Card('card61', 5, 0, this.resources.items[cat_samples[0]],this.resources.items.card_back_6,[ 10.0, 4.5, -9.0]));
        this.cards.push(new Card('card62', 5, 1, this.resources.items[cat_samples[1]],this.resources.items.card_back_6,[ 10.0, 4.5, -9.01]));
        this.cards.push(new Card('card63', 5, 2, this.resources.items[cat_samples[2]],this.resources.items.card_back_6,[ 10.0, 4.5, -9.02]));
    }

    reset_textures()
    {
        let nums = ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20']
        nums.sort(() => 0.5 - Math.random())
        var cat_samples=['cat_01_front_'+nums[0],'cat_01_front_'+nums[1],'cat_01_front_'+nums[2],]
        nums.sort(() => 0.5 - Math.random())
        cat_samples.push('cat_02_front_'+nums[0],'cat_02_front_'+nums[1],'cat_02_front_'+nums[2])
        nums.sort(() => 0.5 - Math.random())
        cat_samples.push('cat_03_front_'+nums[0],'cat_03_front_'+nums[1],'cat_03_front_'+nums[2])
        nums.sort(() => 0.5 - Math.random())
        cat_samples.push('cat_04_front_'+nums[0],'cat_04_front_'+nums[1],'cat_04_front_'+nums[2])
        nums.sort(() => 0.5 - Math.random())
        cat_samples.push('cat_05_front_'+nums[0],'cat_05_front_'+nums[1],'cat_05_front_'+nums[2])
        nums.sort(() => 0.5 - Math.random())
        cat_samples.push('cat_06_front_'+nums[0],'cat_06_front_'+nums[1],'cat_06_front_'+nums[2])
        for (let i = 0; i < this.cards.length; i++) 
        {
            this.cards[i].material[5].map = this.resources.items[cat_samples[i]];
            this.cards[i].material[5].needsUpdate = true;
        }
    }
    
    reset_pos_rot()
    {
        for (let i = 0; i < this.cards.length; i++) 
            this.cards[i].reset_pos_rot()
        this.front_objects=[]
        this.front_layer = false
    }

    hit_object(object)
    {
        for (let i = 0; i < this.cards.length; i++) 
        {
            if (object.name === this.cards[i].mesh.name) 
            {
                if (!(this.cards[i].front))
                {
                    this.cards[i].click_to_front()
                    this.front_layer = true
                } else {
                    this.cards[i].selected()
                }
            }
        }
    }

    link()
    {
        window.open("https://nachtpeter.de/","_blank");
    }

    reset()
    {
        this.reset_pos_rot()
        this.reset_textures()
    }

    clean_up_front()
    {
        for (let i = 0; i < this.front_objects.length; i++) 
        {
            if (!this.front_objects[i].final){
                this.front_objects[i].sad_leave()
            }
            
        }
        this.front_objects=[]
        this.front_layer = false
    }

    update()
    {
        if(this.cards)
            for (let i = 0; i < this.cards.length; i++) 
            {
                this.cards[i].update()
            }
    }
}